import { HttpClient } from '@angular/common/http';
import { Injectable, Signal } from '@angular/core';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { UserService } from 'app/core/user/user.service';
import { Observable, map, tap } from 'rxjs';
import { SignalStateService } from '../service/signal-state-service';
import { Company } from './company.types';

interface CompanyState {
    company: Company | null;
}
@Injectable({ providedIn: 'root' })
export class CompanyService extends SignalStateService<CompanyState> {
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private userService: UserService,
        private navigationService: NavigationService
    ) {
        super({
            company: null,
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get company(): Signal<Company | null> {
        return this.select('company');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in company data
     */
    get(): Observable<Company> {
        return this._httpClient.get<Company>('/api/companies/own').pipe(
            tap((company) => {
                this.setState({
                    company,
                });
            })
        );
    }

    /**
     * Update the company
     *
     * @param company
     */
    update(company: Company): Observable<any> {
        return this._httpClient.patch<Company>('/api/companies', company).pipe(
            map((response) => {
                this.setState({
                    company: response,
                });
                this.userService.get().subscribe();
            })
        );
    }

    /**
     * Create the company
     *
     * @param company
     */
    create(company: Partial<Company>): Observable<any> {
        return this._httpClient.post<Company>('/api/companies', company).pipe(
            map((response) => {
                this.setState({
                    company: response,
                });
                this.userService.get().subscribe();
                this.navigationService.get().subscribe();
            })
        );
    }
}
