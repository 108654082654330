import { HttpClient } from '@angular/common/http';
import { Injectable, Signal } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, tap } from 'rxjs';
import { SignalStateService } from '../service/signal-state-service';

interface NavigationState {
    navigation: Navigation;
}
@Injectable({ providedIn: 'root' })
export class NavigationService extends SignalStateService<NavigationState> {
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        super({
            navigation: {} as Navigation,
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation(): Signal<Navigation> {
        return this.select('navigation');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return this._httpClient.get<Navigation>('/api/navigations').pipe(
            tap((navigation) => {
                this.setState({
                    navigation,
                });
            })
        );
    }
}
